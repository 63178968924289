import React, { useEffect, useState } from "react";
import application from "../application"; // Assuming you have this module for API calls
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/HomeClient.css'; // Importing the CSS file

const HomeClient = () => {
    const [client, setClient] = useState(JSON.parse(localStorage.getItem("user"))); // Initialize client state
    const [showUpdateForm, setShowUpdateForm] = useState(false); // State to show update form
    const [name, setName] = useState(client?.user?.name || ''); // State for Client name
    const [email, setEmail] = useState(client?.user?.username || ''); // State for Client email
    const [stripeAccount, setStripeAccount] = useState(client?.stripe_account || ''); // State for Stripe account
    const [interests, setInterests] = useState(client?.interests || ''); // State for interests
    const [specificNeeds, setSpecificNeeds] = useState(client?.specific_needs || ''); // State for specific needs

    const navigate = useNavigate(); // Initialize navigate

    // Function to handle update form submission
    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        const updatedClient = {
            ...client,
            user: {
                name,
                username: email,
            },
            stripe_account: stripeAccount,
            interests,
            specific_needs: specificNeeds,
        };

        try {
            await application.put(`/update/client/${email}/`, updatedClient);
            setClient(updatedClient);
            localStorage.setItem("user", JSON.stringify(updatedClient)); // Update local storage
            setShowUpdateForm(false);
            alert("Client information updated successfully!");
        } catch (e) {
            alert(e);
        }
    };

    // Function to handle account deletion
    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
        if (confirmDelete) {
            try {
                await application.delete(`/delete/user/${email}/`); // Change the URL according to your API structure
                localStorage.clear(); // Clear local storage
                navigate('/login'); // Navigate to login page
            } catch (error) {
                console.error("Error deleting account", error);
            }
        }
    };

    // Function to toggle update form
    const handleUpdateFormToggle = () => {
        setShowUpdateForm(!showUpdateForm);
    };

    // Function to handle logout
    const handleLogout = () => {
        localStorage.clear(); // Clear local storage
        navigate('/login'); // Navigate to login page
    };

    return (
        <div className="container mt-5">
            {/* Navigation Bar */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
                <div className="container-fluid">
                    <button className="btn btn-primary me-2"
                            onClick={() => setShowUpdateForm(!showUpdateForm)}>
                            {showUpdateForm ? "CLOSE UPDATE FORM" : "UPDATE YOUR INFORMATION"}
                    </button>
                    <button className="btn btn-danger ms-2" onClick={handleDeleteAccount}>
                        DELETE ACCOUNT
                    </button>
                    <button className="btn btn-danger ms-2" onClick={handleLogout}>
                        LOGOUT
                    </button>

                </div>
            </nav>

            <h2>Welcome, {client.user.name}</h2>

            {showUpdateForm && (
                <form onSubmit={handleUpdateSubmit} className="mt-4">
                    <h4>Update Your Information</h4>
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Stripe Account:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={stripeAccount}
                            onChange={(e) => setStripeAccount(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Interests:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={interests}
                            onChange={(e) => setInterests(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Specific Needs:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={specificNeeds}
                            onChange={(e) => setSpecificNeeds(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-success">UPDATE</button>
                </form>
            )}
        </div>
    );
};

export default HomeClient;
