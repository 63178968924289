import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import application from "../application";
import { useNavigate } from "react-router-dom";

function RegistrationForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userType, setUserType] = useState("");

    // Fields for Client
    const [stripeAccount, setStripeAccount] = useState("");
    const [interests, setInterests] = useState("");
    const [specificNeeds, setSpecificNeeds] = useState("");

    // Fields for Expert
    const [education, setEducation] = useState("");
    const [certifications, setCertifications] = useState([]); // Changed to hold file objects
    const [experience, setExperience] = useState("");
    const [areasOfExpertise, setAreasOfExpertise] = useState("");

    // Fields for Corporate
    const [adminName, setAdminName] = useState("");
    const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState("");

    const [errors, setErrors] = useState({}); // State for storing errors
    const navigate = useNavigate();

    const handleUserTypeChange = (event) => {
        const selectedType = event.target.value;
        setUserType(selectedType);

        // Clear all fields when changing user type
        setStripeAccount("");
        setInterests("");
        setSpecificNeeds("");
        setEducation("");
        setCertifications([]);
        setExperience("");
        setAreasOfExpertise("");
        setAdminName("");
        setBusinessRegistrationNumber("");
        setErrors({}); // Reset errors on user type change
    };

    const handleCertificationFileChange = (e) => {
        const files = Array.from(e.target.files);
        setCertifications([...certifications, ...files]); // Append new files to the existing list
    };

    const handleRemoveFile = (index) => {
        const newCertifications = certifications.filter((_, i) => i !== index); // Remove file at the specified index
        setCertifications(newCertifications);
    };

    const validateFields = () => {
        const newErrors = {};
        if (!name) newErrors.name = "Name is required";
        if (!email) newErrors.email = "Email is required";
        if (!password) newErrors.password = "Password is required";

        if (userType === "Expert") {
            if (!education) newErrors.education = "Education is required";
            if (!experience) newErrors.experience = "Experience is required";
            if (!areasOfExpertise) newErrors.areasOfExpertise = "Areas of expertise are required";
        }

        if (userType === "Corporate") {
            if (!adminName) newErrors.adminName = "Admin name is required";
            if (!businessRegistrationNumber) newErrors.businessRegistrationNumber = "Business registration number is required";
        }

        return newErrors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Stop submission if there are errors
        }

        setErrors({}); // Clear previous errors

        try {
            let response;
            switch (userType) {
                case "Client":
                    response = await application.post("/register/client/", {
                        user: {
                            name,
                            username: email,
                            password
                        },
                        stripe_account: stripeAccount,
                        interests,
                        specific_needs: specificNeeds
                    });
                    break;
                case "Expert":
                    const formData = new FormData();
                    formData.append("user[name]", name);
                    formData.append("user[username]", email);
                    formData.append("user[password]", password);
                    formData.append("education", education);
                    formData.append("experience", experience);
                    formData.append("areas_of_expertise", areasOfExpertise);

                    certifications.forEach((file) => {
                        formData.append('certification_files', file);
                    });

                    response = await application.post("/register/expert/", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    break;
                case "Corporate":
                    response = await application.post("/register/corporate/", {
                        user: {
                            name,
                            username: email,
                            password
                        },
                        admin_name: adminName,
                        business_registration_number: businessRegistrationNumber
                    });
                    break;
                default:
                    break;
            }
            navigate('/login');
        } catch (e) {
            if (e.response && e.response.data) {
                const serverErrors = e.response.data;
                const newErrors = { ...errors }; // Keep existing errors

                // Handle user-related errors (assuming username is the email)
                if (serverErrors.user && serverErrors.user.username) {
                    newErrors.email = serverErrors.user.username.join(", "); // Combine error messages
                }

                if (serverErrors.stripe_account && serverErrors.stripe_account.stripe_account) {
                newErrors.stripe_account = serverErrors.stripe_account.stripe_account.join(", "); // Combine error messages
                }

                if (serverErrors.file && serverErrors.file.file) {
                newErrors.certifications = serverErrors.file.file.join(", "); // Combine error messages
                }

                setErrors(newErrors); // Set the errors from the server
            } else {
                setErrors({ general: "An unexpected error occurred. Please try again." }); // Fallback error message
            }
        }
    };

    return (
        <div className="container my-5">
            <div className="card shadow p-4">
                <h2 className="text-center mb-4">Registration Form</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Name: <span className="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required // Make field required
                        />
                        {errors.name && <div className="text-danger">{errors.name}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Email: <span className="text-danger">*</span></label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required // Make field required
                        />
                        {errors.email && <div className="text-danger">{errors.email}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Password: <span className="text-danger">*</span></label>
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required // Make field required
                        />
                        {errors.password && <div className="text-danger">{errors.password}</div>}
                    </div>

                    <div className="mb-4">
                        <label className="form-label">User Type:</label>
                        <select
                            className="form-select"
                            value={userType}
                            onChange={handleUserTypeChange}
                            required // Make field required
                        >
                            <option value="">Select User Type</option>
                            <option value="Client">Client</option>
                            <option value="Expert">Expert</option>
                            <option value="Corporate">Corporate</option>
                        </select>
                    </div>

                    {/* Client-specific fields */}
                    {userType === "Client" && (
                        <>
                            <div className="mb-3">
                                <label className="form-label">Stripe Account:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={stripeAccount}
                                    onChange={(e) => setStripeAccount(e.target.value)}
                                    required // Make field required
                                />
                                {errors.stripe_account && <div className="text-danger">{errors.stripe_account}</div>}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Interests:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={interests}
                                    onChange={(e) => setInterests(e.target.value)}
                                    required // Make field required
                                />
                                {errors.interests && <div className="text-danger">{errors.interests.join(", ")}</div>}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Specific Needs:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={specificNeeds}
                                    onChange={(e) => setSpecificNeeds(e.target.value)}
                                    required // Make field required
                                />
                                {errors.specific_needs && <div className="text-danger">{errors.specific_needs.join(", ")}</div>}
                            </div>
                        </>
                    )}

                    {/* Expert-specific fields */}
                    {userType === "Expert" && (
                        <>
                            <div className="mb-3">
                                <label className="form-label">Education: <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={education}
                                    onChange={(e) => setEducation(e.target.value)}
                                    required // Make field required
                                />
                                {errors.education && <div className="text-danger">{errors.education}</div>}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Experience: <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={experience}
                                    onChange={(e) => setExperience(e.target.value)}
                                    required // Make field required
                                />
                                {errors.experience && <div className="text-danger">{errors.experience}</div>}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Areas of Expertise: <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={areasOfExpertise}
                                    onChange={(e) => setAreasOfExpertise(e.target.value)}
                                    required // Make field required
                                />
                                {errors.areasOfExpertise && <div className="text-danger">{errors.areasOfExpertise}</div>}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Certification Files:</label>
                                <input
                                    type="file"
                                    multiple
                                    className="form-control"
                                    onChange={handleCertificationFileChange}
                                />
                                {errors.certifications && <div className="text-danger">{errors.certifications}</div>}
                                {certifications.length > 0 && (
                                    <ul className="list-unstyled mt-2">
                                        {certifications.map((file, index) => (
                                            <li key={index}>
                                                {file.name}
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm ms-2"
                                                    onClick={() => handleRemoveFile(index)}
                                                >
                                                    Remove
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}

                            </div>
                        </>
                    )}

                    {/* Corporate-specific fields */}
                    {userType === "Corporate" && (
                        <>
                            <div className="mb-3">
                                <label className="form-label">Admin Name: <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={adminName}
                                    onChange={(e) => setAdminName(e.target.value)}
                                    required // Make field required
                                />
                                {errors.adminName && <div className="text-danger">{errors.adminName}</div>}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Business Registration Number: <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={businessRegistrationNumber}
                                    onChange={(e) => setBusinessRegistrationNumber(e.target.value)}
                                    required // Make field required
                                />
                                {errors.businessRegistrationNumber && <div className="text-danger">{errors.businessRegistrationNumber}</div>}
                            </div>
                        </>
                    )}

                    {errors.general && <div className="text-danger">{errors.general}</div>}

                    <button type="submit" className="btn btn-primary">Register</button>
                </form>
            </div>
        </div>
    );
}

export default RegistrationForm;
