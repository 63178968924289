import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Login from "./pages/Login"
import Register from "./pages/Register"
import Home from "./pages/HomeClient"
import NotFound from "./pages/NotFound"
import ProtectedRoute from "./components/ProtectedRoute"
import LoginForm from "./pages/Login";
import GoogleLoginCallback from "./components/GoogleLoginCallback";
import HomeCorporate from "./pages/HomeCorporate";
import HomeClient from "./pages/HomeClient"
import HomeExpert from "./pages/HomeExpert";


function Logout() {
  localStorage.clear()
  return <Navigate to="/login" />
}

function RegisterAndLogout() {
  localStorage.clear()
  return <Register />
}

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
                path="/corporate"
                element={
                    <ProtectedRoute>
                        <HomeCorporate />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/client"
                element={
                    <ProtectedRoute>
                        <HomeClient />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/expert"
                element={
                    <ProtectedRoute>
                        <HomeExpert />
                    </ProtectedRoute>
                }
            />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<RegisterAndLogout />} />
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/auth/login/callback" element={<GoogleLoginCallback />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App