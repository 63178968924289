// LoginForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import application from "../application";
import {Link, useNavigate} from "react-router-dom";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "../constants";
import GoogleButton from "react-google-button";
import GoogleLoginButton from "../components/GoogleLoginButton";

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState(''); // Stanje za grešku
    const [emailError, setEmailError] = useState(''); // Greška za email
    const [passwordError, setPasswordError] = useState(''); // Greška za lozinku

    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();

        const data = {username: email, password: password};

        application.post('/login/', data)
            .then(response => {
                console.log('Login successful:', response.data); //Izbrisati kasnije da se ne ispisuju podaci
                localStorage.setItem(ACCESS_TOKEN, response.data.access);
                localStorage.setItem(REFRESH_TOKEN, response.data.refresh);
                localStorage.setItem("user", JSON.stringify(response.data.user));
                localStorage.setItem('user_type', response.data.user_type);

                navigate('/' + localStorage.getItem('user_type') + '/');
            })
            .catch(error => {
                console.error('Login failed:', error.response.data);
                // Show error message
                // Postavljanje poruke greške
                setError(error.response.data.detail || "Something went wrong. Please try again.");
                // Resetovanje grešaka
                setEmailError('');
                setPasswordError('');
                // Specifične greške
                if (error.response.data.detail.includes("no active account")) {
                    setEmailError("Email not found.");
                } else if (error.response.data.detail.includes("password")) {
                    setPasswordError("Incorrect password.");
                }
            });
    };

    return (
        <div className="container my-5">
            <div className="card shadow p-4">
                <h2 className="text-center mb-4">Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="mb-3">
                        <label className="form-label">Email:</label>
                        <input
                            type="email"
                            className={`form-control ${emailError ? 'is-invalid' : ''}`} // Dodajte klasu za grešku
                            value={email}
                            onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError('');
                                    }
                                }
                            placeholder="Enter your email"
                        />
                        {emailError && <div className="invalid-feedback">{emailError}</div>} {/* Prikaz greške */}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Password:</label>
                        <input
                            type="password"
                            className={`form-control ${passwordError ? 'is-invalid' : ''}`} // Dodajte klasu za grešku
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordError(''); // Resetujte grešku prilikom unosa
                            }}
                            placeholder="Enter your password"
                        />
                        {passwordError && <div className="invalid-feedback">{passwordError}</div>} {/* Prikaz greške */}
                    </div>

                    {error && <div className="alert alert-danger">{error}</div>}

                    <button type="submit" className="btn btn-primary w-100 mt-3">
                        Login
                    </button>
                </form>

                <div className="text-center mt-3">
                    <p>
                        Don't have an account? <Link to="/register">Register here</Link>
                    </p>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <GoogleLoginButton/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LoginForm;
