import React, { useEffect, useState } from "react";
import application from "../application"; // Assuming you have this module for API calls
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/HomeExpert.css'; // Importing the CSS file

const HomeExpert = () => {
    const [expert, setExpert] = useState(JSON.parse(localStorage.getItem("user"))); // Initialize expert state
    const [showUpdateForm, setShowUpdateForm] = useState(false); // State to show update form
    const [name, setName] = useState(expert?.user?.name || ''); // State for Expert name
    const [email, setEmail] = useState(expert?.user?.username || ''); // State for Expert email
    const [education, setEducation] = useState(expert?.education || ''); // State for education
    const [experience, setExperience] = useState(expert?.experience || ''); // State for experience
    const [areasOfExpertise, setAreasOfExpertise] = useState(expert?.areas_of_expertise || ''); // State for areas of expertise
    const [certifications, setCertifications] = useState([]); // State for certifications

    const navigate = useNavigate(); // Initialize navigate

    // Function to fetch expert details
    const fetchExpert = async () => {
        try {
            const response = await application.get(`/expert-detail/${expert.id}/`); // Adjust API call as needed
            setExpert(response.data);
            setName(response.data.user.name);
            setEmail(response.data.user.username);
            setEducation(response.data.education);
            setExperience(response.data.experience);
            setAreasOfExpertise(response.data.areas_of_expertise);
            // Set existing certifications if needed
            setCertifications(response.data.certifications || []);
        } catch (error) {
            console.error("Error fetching expert", error);
        }
    };

    useEffect(() => {
        fetchExpert(); // Fetch expert details on component mount
    }, []);

    // Function for handling the update form submission
    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("user[name]", name);
        formData.append("user[username]", email);
        formData.append("education", education);
        formData.append("experience", experience);
        formData.append("areas_of_expertise", areasOfExpertise);

        // Add all certification files to FormData
        certifications.forEach((file) => {
            formData.append('certification_files', file);
        });

        try {
            await application.put(`/update/expert/${email}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            // Clear the form after successful submission
            setShowUpdateForm(false);
            fetchExpert(); // Reload expert details
            alert("Expert information updated successfully!");
        } catch (e) {
            alert(e);
        }
    };

    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
        if (confirmDelete) {
            try {
                await application.delete(`/delete/user/${email}/`); // Change the URL according to your API structure
                localStorage.clear(); // Clear local storage
                navigate('/login'); // Navigate to login page
            } catch (error) {
                console.error("Error deleting account", error);
            }
        }
    };

    // Function to toggle update form visibility
    const handleUpdateFormToggle = () => {
        setShowUpdateForm(!showUpdateForm);
    };

    // Function to handle logout
    const handleLogout = () => {
        localStorage.clear(); // Clear local storage
        navigate('/login'); // Navigate to login page
    };

    return (
        <div className="container mt-5">
            <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
                <div className="container-fluid">
                    <button className="btn btn-primary me-2"
                            onClick={() => setShowUpdateForm(!showUpdateForm)}>
                        {showUpdateForm ? "CLOSE UPDATE FORM" : "UPDATE YOUR INFORMATION"}
                    </button>
                    <button className="btn btn-danger ms-2" onClick={handleDeleteAccount}>
                        DELETE ACCOUNT
                    </button>
                    <button className="btn btn-danger ms-2" onClick={handleLogout}>
                        LOGOUT
                    </button>

                </div>
            </nav>
            <h2>Welcome, {expert.user.name}</h2>
            {showUpdateForm && (
                <form onSubmit={handleUpdateSubmit} className="mt-4">
                    <h4>Update Your Information</h4>
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Education:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Experience:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Areas of Expertise:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={areasOfExpertise}
                            onChange={(e) => setAreasOfExpertise(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Certification Files:</label>
                        <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={(e) => {
                                const files = Array.from(e.target.files);
                                setCertifications([...certifications, ...files]);
                            }}
                        />
                        {certifications.length > 0 && (
                            <ul>
                                {certifications.map((file, index) => (
                                    <li key={index}>
                                        {file.name}
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => {
                                            const updatedCertifications = certifications.filter((_, i) => i !== index);
                                            setCertifications(updatedCertifications);
                                        }}>Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <button type="submit" className="btn btn-success">UPDATE</button>
                </form>
            )}
        </div>
    );
};

export default HomeExpert;
