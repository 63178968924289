import React, { useEffect, useState } from "react";
import application from "../application"; // Assuming you have this module for API calls
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/HomeCorporate.css'; // Importing the CSS file

const HomeCorporate = () => {
    const [experts, setExperts] = useState([]);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(null); // Store the ID of the expert to be updated
    const [showCorporateUpdateForm, setShowCorporateUpdateForm] = useState(false); // State to show CorporateUser update form
    const [corporateUser, setCorporateUser] = useState(JSON.parse(localStorage.getItem("user"))); // Initialize corporateUser state
    const [corpName, setCorpName] = useState(corporateUser?.user?.name || ''); // State for CorporateUser name
    const [corpEmail, setCorpEmail] = useState(corporateUser?.user?.username || ''); // State for CorporateUser email
    const [adminName, setAdminName] = useState(corporateUser?.admin_name || ''); // State for Admin name
    const [businessRegNumber, setBusinessRegNumber] = useState(corporateUser?.business_registration_number || ''||''); // State for Business Registration Number
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [education, setEducation] = useState('');
    const [experience, setExperience] = useState('');
    const [areasOfExpertise, setAreasOfExpertise] = useState('');
    const [certifications, setCertifications] = useState([]);
    const [showExperts, setShowExperts] = useState(true); // State to show/hide experts

    const navigate = useNavigate(); // Initialize navigate

    // Function to fetch experts
    const fetchExperts = async () => {
        try {
            const response = await application.get(`/corporate/expert-list/${corporateUser.id}/`); // Change the URL according to your API structure
            setExperts(response.data);
        } catch (error) {
            console.error("Error fetching experts", error);
        }
    };

    useEffect(() => {
        fetchExperts();
    }, []);

    // Function for changing certification files
    const handleCertificationFileChange = (event) => {
        const files = Array.from(event.target.files);
        setCertifications([...certifications, ...files]); // Append new files to the existing list
    };

    // Function to remove certification files
    const handleRemoveFile = (index) => {
        const updatedCertifications = certifications.filter((_, i) => i !== index);
        setCertifications(updatedCertifications);
    };

    // Function to submit the registration form
    const handleRegisterSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("user[name]", name);
        formData.append("user[username]", email);
        formData.append("user[password]", password);
        formData.append("education", education);
        formData.append("experience", experience);
        formData.append("areas_of_expertise", areasOfExpertise);
        formData.append("corporate_user_id", corporateUser.id);

        // Add all certification files to FormData
        certifications.forEach((file) => {
            formData.append('certification_files', file);
        });

        try {
            await application.post("corporate/add-expert/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            // Clear the form after successful submission
            setShowRegisterForm(false);
            setName('');
            setEmail('');
            setPassword('');
            setEducation('');
            setExperience('');
            setAreasOfExpertise('');
            setCertifications([]);
            fetchExperts(); // Reload experts
        } catch (e) {
            alert(e);
        }
    };

    // Function to submit the update form
    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("user[name]", name);
        formData.append("user[username]", email);
        formData.append("education", education);
        formData.append("experience", experience);
        formData.append("areas_of_expertise", areasOfExpertise);

        // Add all certification files to FormData
        certifications.forEach((file) => {
            formData.append('certification_files', file);
        });

        try {
            await application.put(`/update/expert/${email}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            // Clear the form after successful submission
            setShowUpdateForm(null);
            setName('');
            setEmail('');
            setPassword('');
            setEducation('');
            setExperience('');
            setAreasOfExpertise('');
            setCertifications([]);
            fetchExperts(); // Reload experts
        } catch (e) {
            alert(e);
        }
    };

    // Function to handle delete expert
    const handleDeleteExpert = async (email) => {
        try {
            await application.delete(`/delete/expert/${email}/`); // Change the URL according to your API structure
            fetchExperts(); // Reload experts
        } catch (error) {
            console.error("Error deleting expert", error);
        }
    };

    // Function to open update form with expert's data
    const handleEditExpert = (expert) => {
        setShowUpdateForm(expert.id);
        setName(expert.user.name);
        setEmail(expert.user.username);
        setEducation(expert.education);
        setExperience(expert.experience);
        setAreasOfExpertise(expert.areas_of_expertise);
        setCertifications([]); // Clear previous certifications
        setShowRegisterForm(false); // Close registration form if open
    };

    // Function to open register form and clear any filled fields
    const handleRegisterFormToggle = () => {
        if (showUpdateForm) {
            // Clear update fields when switching to register form
            setShowUpdateForm(null);
        }
        setShowRegisterForm(!showRegisterForm);
        if (!showRegisterForm) {
            // Clear registration form fields
            setName('');
            setEmail('');
            setPassword('');
            setEducation('');
            setExperience('');
            setAreasOfExpertise('');
            setCertifications([]);
        }
    };

    // Function to close the update form
    const handleCloseUpdateForm = () => {
        setShowUpdateForm(null);
        setName('');
        setEmail('');
        setPassword('');
        setEducation('');
        setExperience('');
        setAreasOfExpertise('');
        setCertifications([]);
    };

    // Function to handle CorporateUser update
    const handleCorporateUpdateSubmit = async (event) => {
        event.preventDefault();
        const updatedCorporateUser = {
            ...corporateUser,
            user: {
                name: corpName, // Use corpName here
                username: corpEmail // Use corpEmail here
            },
            admin_name: adminName, // Include admin_name
            business_registration_number: businessRegNumber // Include business_registration_number
        };

        try {
            await application.put(`/update/corporate/${corpEmail}/`, updatedCorporateUser);
            setCorporateUser(updatedCorporateUser);
            setShowCorporateUpdateForm(false);
            // Clear fields after successful submission
            setCorpName(corporateUser.user.name); // Reset to original
            setCorpEmail(corporateUser.user.username); // Reset to original
            setAdminName(corporateUser.user.admin_name); // Reset admin name
            setBusinessRegNumber(corporateUser.user.business_registration_number); // Reset business registration number
        } catch (e) {
            alert(e);
        }
    };

    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
        if (confirmDelete) {
            try {
                await application.delete(`/delete/user/${corpEmail}/`); // Change the URL according to your API structure
                localStorage.clear(); // Clear local storage
                navigate('/login'); // Navigate to login page
            } catch (error) {
                console.error("Error deleting account", error);
            }
        }
    };

    // Function to handle logout
    const handleLogout = () => {
        localStorage.clear(); // Clear localStorage
        navigate('/login'); // Navigate to login page
    };

    return (
        <div className="container mt-5">
            <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
                <div className="container-fluid">
                    <button className="btn btn-primary me-2"
                            onClick={() => setShowCorporateUpdateForm(!showCorporateUpdateForm)}>
                        {showCorporateUpdateForm ? "CLOSE CORPORATE INFO EDIT" : "EDIT CORPORATE INFO"}
                    </button>
                    <button className="btn btn-primary me-2" onClick={handleRegisterFormToggle}>
                        {showRegisterForm ? "CLOSE REGISTER NEW EXPERT" : "REGISTER NEW EXPERT"}
                    </button>
                    <button className="btn btn-primary" onClick={() => setShowExperts(!showExperts)}>
                        {showExperts ? "HIDE EXPERTS" : "SHOW EXPERTS"}
                    </button>
                    <button className="btn btn-danger" onClick={handleDeleteAccount}>
                        DELETE ACCOUNT
                    </button>
                    <button className="btn btn-danger ms-2" onClick={handleLogout}>LOGOUT</button>
                </div>
            </nav>

            <h2>Experts for {corporateUser.user.name}</h2>

            {showCorporateUpdateForm && (
                <form onSubmit={handleCorporateUpdateSubmit} className="mt-4">
                    <h4>Update Corporate Information</h4>
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={corpName} // Use corpName here
                            onChange={(e) => setCorpName(e.target.value)} // Update corpName here
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            value={corpEmail} // Use corpEmail here
                            onChange={(e) => setCorpEmail(e.target.value)} // Update corpEmail here
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Admin Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={adminName} // Use adminName here
                            onChange={(e) => setAdminName(e.target.value)} // Update adminName here
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Business Registration Number:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={businessRegNumber} // Use businessRegNumber here
                            onChange={(e) => setBusinessRegNumber(e.target.value)} // Update businessRegNumber here
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-success">UPDATE</button>
                </form>
            )}
            {showRegisterForm && (
                <form onSubmit={handleRegisterSubmit} className="mt-4">
                    <h4>Register New Expert</h4>
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password:</label>
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Education:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Experience:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Areas of Expertise:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={areasOfExpertise}
                            onChange={(e) => setAreasOfExpertise(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Certification Files:</label>
                        <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={handleCertificationFileChange}
                        />
                        {certifications.length > 0 && (
                            <ul>
                                {certifications.map((file, index) => (
                                    <li key={index}>
                                        {file.name} <button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemoveFile(index)}>Remove</button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <button type="submit" className="btn btn-success">REGISTER</button>
                </form>
            )}

            {showExperts && (
                <div className="mt-4">
                    <h4>Experts List</h4>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {experts.map((expert) => (
                                <tr key={expert.id}>
                                    <td>{expert.user.name}</td>
                                    <td>{expert.user.username}</td>
                                    <td>
                                        <button className="btn btn-warning me-2" onClick={() => handleEditExpert(expert)}>EDIT</button>
                                        <button className="btn btn-danger" onClick={() => handleDeleteExpert(expert.user.username)}>DELETE</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {showUpdateForm && (
                <form onSubmit={handleUpdateSubmit} className="mt-4">
                    <h4>Update Expert</h4>
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Education:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Experience:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Areas of Expertise:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={areasOfExpertise}
                            onChange={(e) => setAreasOfExpertise(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Certification Files:</label>
                        <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={handleCertificationFileChange}
                        />
                        {certifications.length > 0 && (
                            <ul>
                                {certifications.map((file, index) => (
                                    <li key={index}>
                                        {file.name} <button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemoveFile(index)}>Remove</button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <button type="submit" className="btn btn-success">UPDATE</button>
                    <button type="button" className="btn btn-secondary ms-2" onClick={handleCloseUpdateForm}>CLOSE</button>
                </form>
            )}
        </div>
    );
};

export default HomeCorporate;
