// src/components/GoogleLoginCallback.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants';
import application from "../application";

const GoogleLoginCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      const params = new URLSearchParams(window.location.search);
      const accessToken = params.get('access');
      const refreshToken = params.get('refresh');
      const error = params.get('error');
      const user_type = params.get('user_type');
      const username = params.get('username');

      if (accessToken && refreshToken) {
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        localStorage.setItem('user_type', user_type);

        try {
          const response = await application.get(`/get-user/${username}/`);
          localStorage.setItem("user", JSON.stringify(response.data.user));

          // Navigate to your desired page after storing user data
          navigate(`/${user_type}`);
        } catch (fetchError) {
          console.error('Error fetching user details:', fetchError);
          alert('Failed to fetch user details. Please try again.');
          navigate('/register'); // Optionally redirect to register on failure
        }
      } else if (error) {
        // Handle the error, e.g., user not found
        alert('User not found. Please register.');
        navigate('/register'); // Redirect to the registration page
      }
    };

    fetchUserDetails(); // Call the async function
  }, [navigate]);

  return (
    <div>
      <h2>Loading...</h2>
    </div>
  );
};

export default GoogleLoginCallback;